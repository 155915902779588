import React from 'react';

import classNames from 'classnames';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
// import Button from 'components/CustomButtons/Button.js';
import Email from '@material-ui/icons/Email';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Footer from 'components/Footer/Footer.js';
import Parallax from 'components/Parallax/Parallax.js';

import AuthUserContext from './context';
import { withFirebase } from 'utils/Firebase';

import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import defaultMaterialTheme from "utils/Themes/defaultMaterialTheme";
import defaultBtnMaterialTheme from "utils/Themes/defaultBtnMaterialTheme";
import {
  primaryColor,
  grayColor,
  blackColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";

import Waiting from "utils/Waiting";
import Transition from "utils/Transition";
import PaperComponent from "utils/PaperComponent";
import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";

import { Link } from 'react-router-dom';
import LineStyle from '@material-ui/icons/LineStyle';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password')
// &&
// authUser.providerData
//   .map(provider => provider.providerId)
//   .includes('roles');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false, _isOpenWaiting_: false, confirmationSent: false };
    }

    onSendEmailVerification = () => {
      console.log(`onSendEmailVerification`)
      // this.props.firebase
      //   .doSendEmailRedirect()
      //   .then(() => this.setState({ isSent: true }));
    };

    componentWillMount = async () => {
      // console.log('start withEmailVerification.componentWillMount');

      // console.log('end withEmailVerification.componentWillMount');
    };

    componentDidMount = async () => {
      // console.log('start withEmailVerification.componentDidMount');

      // console.log('end withEmailVerification.componentDidMount');
    };

    componentDidUpdate = async () => {
      // console.log('start withEmailVerification.componentDidUpdate');

      // console.log('end withEmailVerification.componentDidUpdate');
    };

    render() {
      // console.log('start withEmailVerification.render');
      const { classes } = this.props;
      // console.log('end withEmailVerification.render');
      return (
        <>
          <AuthUserContext.Consumer>
            {authUser =>
              needsEmailVerification(authUser) ? (
                <React.Fragment>
                  <Parallax style={{ marginTop: 0 }}
                  // image={require('assets/img/bg10.jpg')}
                  // className={classes.parallax}
                  >
                    <div className={classes.container}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={6} md={6}>
                          <Card
                            className={classNames(
                              classes.mainRaised
                            )}
                          >
                            <CardBody>
                              <GridContainer justify="center">
                                <GridItem xs={12} sm={10} md={10}>
                                  {!authUser.emailSent || !this.state._isOpenWaiting_ ? (
                                    <React.Fragment>
                                      {/* {`${JSON.stringify(authUser.emailSent)}`} */}
                                      {/* {`${JSON.stringify(authUser)}`} */}
                                      {/* {`${JSON.stringify(authUser.roles)}`} */}
                                      <InfoArea
                                        className={classes.infoArea}
                                        title="Email confirmation sent"
                                        description=""
                                        icon={Email}
                                        iconColor="primary"
                                      />
                                      {/* <div style={{ color: "blackColor" }}>
                                        Please check your Inbox and Spam folder. If you didn’t get the confirmation message, click the following to&nbsp;
                                        <text
                                          style={{ color: primaryColor[0], textDecoration: "underline", cursor: "pointer" }}
                                          onClick={() => {
                                            this.setState({ _isOpenWaiting_: true });
                                            // alert(`onSendEmailVerification`)
                                            this.props.firebase
                                              .doSendEmailRedirect()
                                              .then(() => {
                                                this.setState({ isSent: true });
                                              })
                                            setTimeout(() => {
                                              this.setState({ _isOpenWaiting_: false, confirmationSent: true })
                                              setTimeout(() => {
                                                this.setState({ confirmationSent: false })
                                              }, 2000)
                                            }, 5000)

                                          }}>
                                          resend the confirmation email.
                                        </text>
                                      </div> */}
                                      <div className={classes.container} style={{ fontSize: "16px", fontWeight: "400", color: "blackColor" }}>
                                        {authUser.roles.includes('MEMBER') ? (
                                          <p style={{ fontSize: "16px", color: "blackColor" }}>You have almost completed your registration as a Notary Member for NP360.</p>
                                        ) : (
                                          <p style={{ fontSize: "16px", color: "blackColor" }}>You have almost completed your registration to NP360.</p>
                                        )}
                                        <br />
                                        <p style={{ fontSize: "16px", color: "blackColor" }}>Important: validate your email address now. Please check your inbox or spam folder to find the validation email that we sent (Subject: Verify your email for Notary Public 360."). </p>
                                        <br />
                                        <p style={{ fontSize: "16px", color: "blackColor" }}>Open the email and click the validation link. If you cannot find the validation email,&nbsp;
                                          <text
                                            style={{ color: primaryColor[0], textDecoration: "underline", cursor: "pointer" }}
                                            onClick={() => {
                                              this.setState({ _isOpenWaiting_: true });
                                              // alert(`onSendEmailVerification`)

                                              if (authUser.roles.includes('MEMBER')) {
                                                this.props.firebase
                                                  .doMemberSendEmailRedirect()
                                                  .then(() => {
                                                    this.setState({ isSent: true });
                                                  })
                                                setTimeout(() => {
                                                  this.setState({ _isOpenWaiting_: false, confirmationSent: true })
                                                  setTimeout(() => {
                                                    this.setState({ confirmationSent: false })
                                                  }, 2000)
                                                }, 5000)
                                              } else {
                                                this.props.firebase
                                                  .doSendEmailRedirect()
                                                  .then(() => {
                                                    this.setState({ isSent: true });
                                                  })
                                                setTimeout(() => {
                                                  this.setState({ _isOpenWaiting_: false, confirmationSent: true })
                                                  setTimeout(() => {
                                                    this.setState({ confirmationSent: false })
                                                  }, 2000)
                                                }, 5000)
                                              }
                                            }}>
                                            click this to have it resent
                                          </text>
                                          &nbsp;or send us an email at <div style={{ color: primaryColor[0] }}>business@notarypublic360.com.</div> </p>
                                        <br />

                                      </div>
                                    </React.Fragment>
                                  ) :
                                    null
                                  }
                                </GridItem>

                              </GridContainer>
                            </CardBody>
                            <br />
                            <br />
                            <br />

                          </Card>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </Parallax>


                </React.Fragment>
              ) : (
                <Component {...this.props} />
              )
            }
          </AuthUserContext.Consumer >

          <Dialog
            open={this.state._isOpenWaiting_}
            // onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              {" "}
            </DialogTitle>
            <DialogContent>
              <div id="waitingD" style={{ marginTop: -5, marginBottom: 10 }}>
                <Waiting Transition={Transition} message={"Sending ..."} isOpenWaiting={this.state._isOpenWaiting_} />
              </div>
              <DialogContentText></DialogContentText>
            </DialogContent>
          </Dialog>

          <Dialog
            open={this.state.confirmationSent}
            // onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              {" "}
            </DialogTitle>
            <DialogContent>
              <text
                style={{ fontSize: 16, fontWeight: "bold", color: blackColor, cursor: "pointer" }}
                onClick={() => {
                  this.setState({ confirmationSent: false })
                }}>
                Confirmation sent.
              </text>
            </DialogContent>
            <br />
          </Dialog>
        </>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;