// handles context for user (principal)

import React, { createContext, useState, useContext } from "react";
import { useFormik } from 'formik';
import * as Yup from "yup";

const userContext = createContext();
const Provider = userContext.Provider;

const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);

  // formik object for user form
  const formikObject = (props) => ({
    ...props,
		innerRef: props.formRef,
		initialValues: {
      firstName: '',
      lastName: '',
      mobileNumber: ''
    },
		validateOnChange: false,
		validateOnBlur: false,
    validationSchema: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      mobileNumber: Yup.string()
        .matches(/^(09|\+639)\d{9}$/, {
          message: 'Please enter a valid Philippine mobile number.'
        })
        .required('Required.'),
    }),
  });

  return (
    <Provider
      value={{
        user, setUser,
        formikObject
      }}
    >
      {children}
    </Provider>
  )
}

const useUser = () => useContext(userContext);

export { UserProvider, useUser }