import { createTheme } from "@material-ui/core";

const defaultBtnMaterialTheme = createTheme({
  palette: {
    // primary: red, //aae6bf
    primary: {
      main: "#aae6bf",
      // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    secondary: {
      main: "#aae6bf",
      // main: "#fff"
    },

    // grey: { main: "#22BF19" }
  },
  overrides: {
    MuiButton: {
      root: {
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        borderRadius: 15,
        lineHeight: 1.3,
      },
      label: {
        fontWeight: 700,
        fontSize: "18px",
        fontColor: "#aae6bf",
      }
    },
    MuiFormLabel: {
      root: {
        // "&$focused": {
        color: "#999",
        fontSize: "22px",
        backgroundColor: "#fff",
        margin: "-2px 0 0 -5px",
        padding: "0 10px 0 10px"
        // }
      }
    }
  }
});

export default defaultBtnMaterialTheme