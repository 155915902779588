import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { GET_BOOKING_UPDATE_USER } from 'utils/Graphql/queries';
import { SET_BOOKING_AS_VIEWED } from 'utils/Graphql/mutations';
import { useSnackbar } from 'notistack';
import { useNotaries } from 'utils/Notaries';

import MuiButton from "@material-ui/core/Button";

const useBookingNotification = (props) => {
  const { setBookingUpdates, bookingUpdates, classes } = props;
  const { authUserContext } = useNotaries();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  const [snackBarKeys, setSnackBarKeys] = useState({});
  let history = useHistory();

  const [getBookingUpdateUser, {
    loading: bookingUpdateLoading,
    error: bookingUpdateError,
    data: bookingUpdateData,
    startPolling,
    stopPolling,
    previousData,
    refetch,
  }] = useLazyQuery(GET_BOOKING_UPDATE_USER, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log(data)
    }
  });

  const actionButton = (
    key, 
    userSearchTextId, 
    status, 
    link, 
    history
  ) => (
    <React.Fragment key={key}>
      <MuiButton
        variant="text"
        classes={classes}
        style={{
          marginRight: 5
        }}
        onClick={() => {
          console.log(userSearchTextId)
          setViewed({
            variables: {
              userSearchTextId
            }
          })
          closeSnackbar(key)
        }}
      >
        dismiss
      </MuiButton>
      {status === "CLASSIFIED" ?
        <MuiButton
          variant="contained"
          // size="small"
          classes={classes}
          style={{
            width: "100%"
          }}
          onClick={() => {
            console.log(link)
            history.push(link)
            setViewed({
              variables: {
                userSearchTextId
              }
            })
            closeSnackbar(key)
          }}
        >
          View Results
        </MuiButton>
        : null}
    </React.Fragment>
  );

  const clearSnackBars = () => {
    for (let i in snackBarKeys) {
      closeSnackbar(snackBarKeys[i]);
    }
    // setBookingUpdates([]);
    setSnackBarKeys({});
  }

  const filterBookingData = (bookings) => {
    if (authUserContext?.user?.role === 'USER') {
      if (bookings?.length > 0) {
        let bookingsArray = bookings.filter(booking => booking.status === "CLASSIFIED" || booking.status === "DENIED_BY_SORTER").map(booking => {
          return {
            ...booking,
            actionLink: booking.status === "CLASSIFIED" ? `/auth/pricinglink?docType=${booking.docType
            }&documentTemplateId=${booking.documentTemplate.id
            }&amount=${booking.amount
            }&lat=${booking.lat
            }&lng=${booking.lng
            }&userSearchTextId=${booking.id}` : null
          }
        });
        console.log(bookingsArray);
        return bookingsArray;
        // setBookingUpdates(bookingsArray);
      }
    } else {
      clearSnackBars();
    }
  }

  const addBookingSnackBars = (bookingsArray) => {
    if (bookingsArray?.length > 0) {
      const keys = bookingsArray.reduce((acc, val) => {
        console.log(val);
        let snackbarKey;
        if (val.status === "CLASSIFIED") {
          snackbarKey = enqueueSnackbar(`Your document with invoice number ${val?.invoiceNo || val?.transactionId} has been classified. We will email your results shortly.`, {
            preventDuplicate: true,
            action: (key) => actionButton(key, val.id, val.status, val.actionLink, history),
            variant: 'success',
            persist: true,
          });
        } else if (val.status === "DENIED_BY_SORTER") {
          snackbarKey = enqueueSnackbar(`The document with invoice number (${val?.invoiceNo || val?.transactionId}) uploaded is not valid for notarization. Please check your email to take corrective action or call us at xxx-555-1234.`, {
            preventDuplicate: true,
            action: (key) => actionButton(key, val.id, val.status, `/usr/dashboard`, history),
            variant: 'warning',
            persist: true,
          })
        }
        return { ...acc, [val.id]: snackbarKey };
      }, {});
      setSnackBarKeys(keys);
    } else {
      clearSnackBars();
    }
  }

  useEffect(() => {
    console.log(authUserContext);
    if (authUserContext?.user?.role === "USER") {
      getBookingUpdateUser({
        variables: {
          userId: authUserContext?.user?.id,
        }}
      );
      // startPolling();
      console.log(authUserContext);
    } else {
      console.log(authUserContext?.user?.role)
      clearSnackBars();
      stopPolling();
    }
  }, [authUserContext])

  useEffect(() => {
    console.log(bookingUpdateData)
    if (bookingUpdateData) {
      console.log(bookingUpdateData)
      let bookings = bookingUpdateData?.getBookingUpdateUser;
      const bookingsArray = filterBookingData(bookings);
      addBookingSnackBars(bookingsArray);
    }
    return () => {
      clearSnackBars();
    }
  }, [bookingUpdateData]);

  // useEffect(() => {
  //   if (bookingUpdates?.length > 0) {
  //     const keys = bookingUpdates.reduce((acc, val) => {
  //       console.log(val);
  //       let snackbarKey;
  //       if (val.status === "CLASSIFIED") {
  //         snackbarKey = enqueueSnackbar(`Your document with invoice number ${val?.invoiceNo || val?.transactionId} has been classified. We will email your results shortly.`, {
  //           preventDuplicate: true,
  //           action: (key) => actionButton(key, val.id, val.status, val.actionLink, history),
  //           variant: 'success',
  //           persist: true,
  //         });
  //       } else if (val.status === "DENIED_BY_SORTER") {
  //         snackbarKey = enqueueSnackbar(`The document with invoice number (${val?.invoiceNo || val?.transactionId}) uploaded is not valid for notarization. Please check your email to take corrective action or call us at xxx-555-1234.`, {
  //           preventDuplicate: true,
  //           action: (key) => actionButton(key, val.id, val.status, `/usr/dashboard`, history),
  //           variant: 'warning',
  //           persist: true,
  //         })
  //       }
  //       return { ...acc, [val.id]: snackbarKey };
  //     }, {});
  //     setSnackBarKeys(keys);
  //   } else {
  //     clearSnackBars();
  //   }

  //   return () => {
  //     // setBookingUpdates([]);
  //     clearSnackBars();
  //   }
  // }, [bookingUpdates]);

  useEffect(() => {
    console.log(setViewData);
  }, [setViewData]);

  const [setViewed, { 
    data: setViewData,
    error: setViewError,
    loading: setViewLoading 
  }] = useMutation(SET_BOOKING_AS_VIEWED);

  return { 
    startPolling,
    stopPolling,
    getBookingUpdateUser,
    setViewed,
    enqueueSnackbar,
    closeSnackbar,
    clearSnackBars,
  };
}

export default useBookingNotification;