import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from 'utils/Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser'))
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          authUser = { ...authUser, email: authUser.providerData[0].email, authProvider: authUser.providerData[0].providerId }
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.setState({ authUser });

          // if (!this.props.firebase.justSignedUp) {
          //   debugger;
          //   authUser = { ...authUser, email: authUser.providerData[0].email, authProvider: authUser.providerData[0].providerId }
          //   localStorage.setItem('authUser', JSON.stringify(authUser));
          //   this.setState({ authUser });
          // } else {
          //   debugger
          //   localStorage.removeItem('authUser');
          //   this.setState({ authUser: null });
          // }
        },
        () => {
          debugger
          localStorage.removeItem('authUser');
          this.setState({ authUser: null });
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} authUser={this.state.authUser} />
        </AuthUserContext.Provider>
      );

    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
