import React from 'react';
import Typography from "@material-ui/core/Typography";
import Spinner from 'components/Spinner/Spinner';
// import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress'
import {
  primaryColor,
  grayColor,
  successColor,
  whiteColor
} from 'assets/jss/material-kit-pro-react.js';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     '& > * + *': {
//       marginLeft: theme.spacing(2),
//     },
//   },
// }));

const Waiting = props => {
  // const classes = useStyles();
  if (!props.isOpenWaiting)
    return null;

  return (
    <div
      open={props.isOpenWaiting}
    >
      <Spinner />
      {/* <div class="loader" style={{ height: '80px', width: '80px', display: 'block', margin: 'auto', marginBottom: '80px' }}>
        <CircularProgress color={primaryColor[0]} />
      </div> */}

      <Typography style={{ margin: '-60px 0 0 0', textAlign: 'center' }}>
        {props.message ? props.message : 'Please wait...'}
      </Typography>
    </div>
  )
}

export default Waiting;