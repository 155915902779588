import { createTheme } from "@material-ui/core";

const NotificationTheme = createTheme({
  palette: {
    primary: {
      main: "#609c74",
      contrastText: '#fff',
    },
    secondary: {
      main: "#000000"
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
      label: {
        fontWeight: 600,
        fontSize: "14px",
        // padding: "-5px -5px -5px -5px",
        // fontHeight: "1px",
        // margin: "-10px -2px -10px -2px",
      }
    }
  }
})

export default NotificationTheme