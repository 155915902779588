import React, { createContext, useState, useContext } from "react";

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

function NotariesProvider({ children }) {
  const [gCashResult, setGCashResult] = useState(false);
  const [authUserContext, setAuthUserContext] = useState(null);
  const [notary, setNotary] = useState(null);
  const [notary_, setNotary_] = useState(null);
  const [notaries, setNotaries] = useState([]);
  const [notariesZ, setNotariesZ] = useState([]);
  const [payment, setPayment] = useState(null);
  const [price, setPrice] = useState("");
  const [accessibleAverage, setAccessibleAverage] = useState(0.0);
  const [fastAverage, setFastAverage] = useState(0.0);
  const [friendlyAverage, setFriendlyAverage] = useState(0.0);
  const [numberOfRatings, setNumberOfRatings] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [refreshMapPrice, setRefreshMapPrice] = useState(0);
  const [center, setCenter] = useState(null);
  const [openDirections, setOpenDirections] = useState(false);
  const [originLocation, setOriginLocation] = useState(null);
  const [targetLocation, setTargetLocation] = useState(null);
  const [originAddress, setOriginAddress] = useState(null);
  const [targetAddress, setTargetAddress] = useState(null);

  const [distanceFromCenter, setDistanceFromCenter] = useState(500);
  const [openTitle, setOpenTitle] = useState("");
  const [openMessage, setOpenMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [documentTypes, setDocumentTypes] = useState(null);
  const [docType, setDocType] = useState("");

  const [files, setFiles] = useState([]);
  const [uploadType, setUploadType] = useState("multiple");
  const [isUserNotSignatory, setIsUserNotSignatory] = useState(true);
  const [signatories, setSignatories] = useState([]);

  const [transactionId, setTransactionId] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [fromDocument, setFromDocument] = useState(false);
  const [initialLocation, setInitialLocation] = useState([]);

  const [level1, setLevel1] = useState("");
  const [level2, setLevel2] = useState("");
  const [amount, setAmount] = useState("");

  const [lvl0, setLvl0] = useState("");
  const [lvl1, setLvl1] = useState("");
  const [lvl2, setLvl2] = useState("");
  const [lvl3, setLvl3] = useState("");
  const [amt, setAmt] = useState("");
  const [docsType, setDocsType] = useState(null);
  const [displayAmount, setDisplayAmount] = useState(null);

  const [notaryFee, setNotaryFee] = useState("");

  const [paymentMethodId, setPaymentMethodId] = useState("")

  const [gCashSource, setGCashSource] = useState("")

  const [documentOption, setDocumentOption] = useState("") //upload/describe
  const [booking, setBooking] = useState({})

  const [lastLocation, setLastLocation] = useState("");
  const [lastLocationLng, setLastLocationLng] = useState(0);
  const [lastLocationLat, setLastLocationLat] = useState(0);

  const [clickNext, setClickNext] = useState(false)

  const [openDocument, setOpenDocument] = useState(false);
  const [openDescribe, setOpenDescribe] = useState(false);
  const [openOptionsStep2, setOpenOptionsStep2] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [openOptionsMain, setOpenOptionsMain] = useState(false);
  const [openMapBook, setOpenMapBook] = useState(false);
  const [openMapBookPricing, setOpenMapBookPricing] = useState(false);
  const [refreshMapBook, setRefreshMapBook] = useState(0)
  const [noInit, setNoInit] = useState(false)
  const [caller, setCaller] = useState("MAPBOOK_PRICING")

  const [resetDescibeItems, setResetDescibeItems] = useState(0)

  const [openFeesRates, setOpenFeesRates] = useState(true)
  const [openFeesRatesUpdate, setOpenFeesRatesUpdate] = useState(false)

  const [memberId, setMemberId] = useState("")

  const [rowDataFeesRates, setRowDataFeesRates] = useState([]);
  const [gridApi, setGridApi] = useState(null)
  const [feesRates, setFeesRates] = useState([])
  const [valueFeesRates, setValueFeesRates] = useState("ALL");
  const [membersArray, setMembersArray] = useState([]);
  const [valueMember, setValueMember] = useState([]);
  // userSearchTxId
  const [userSearchTxId, setUserSearchTxId] = useState("");
  const [userSearchTx, setUserSearchTx] = useState("");
  const [appointment, setAppointment] = useState(0);
  const [maxNotaries, setMaxNotaries] = useState(200);

  // const [openSearchPricing, setOpenSearchPricing] = useState(false);
  const [openSearchLocation, setOpenSearchLocation] = useState(false);

  const [province, setProvince] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [barangay, setBarangay] = useState("");
  const [cityMunicipality, setCityMunicipality] = useState("");
  const [subCityMunicipality, setSubCityMunicipality] = useState("");

  const [openSearchLocationPrice, setOpenSearchLocationPrice] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [openPaymentNotice, setOpenPaymentNotice] = useState(false);
  const [openPaymentOption, setOpenPaymentOption] = useState(false);
  const [step, setStep] = useState('PREVIEW_NOTARIES');
  const [isOpenWaiting, setIsOpenWaiting] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState('');
  const [openPaymentUserDetails, setOpenPaymentUserDetails] = useState(false);
  const [openPaymentRequiredNotification, setOpenPaymentRequiredNotification] = useState(false);
  const [openExistingPrincipal, setOpenExistingPrincipal] = useState(false);
  const [openResultsColocationStep2, setOpenResultsColocationStep2] = useState(false);
  const [openBookNotaryNotice, setOpenBookNotaryNotice] = useState(false);
	const [openAppointments, setOpenAppointments] = useState(false);
	const [paymentCheckoutUrl, setPaymentCheckoutUrl] = useState(null);
  const [openPaymentCheckoutView, setOpenPaymentCheckoutView] = useState(false);

  return (
    <LocalStateProvider
      value={{
        authUserContext,
        setAuthUserContext,
        notary,
        setNotary,
        notary_,
        setNotary_,
        notaries,
        setNotaries,
        notariesZ,
        setNotariesZ,
        price,
        setPrice,
        documentTypes,
        setDocumentTypes,
        accessibleAverage,
        setAccessibleAverage,
        fastAverage,
        setFastAverage,
        friendlyAverage,
        setFriendlyAverage,
        numberOfRatings,
        setNumberOfRatings,
        payment,
        setPayment,
        refresh,
        setRefresh,
        refreshMapPrice,
        setRefreshMapPrice,
        center,
        setCenter,
        openDirections,
        setOpenDirections,
        originLocation,
        setOriginLocation,
        targetLocation,
        setTargetLocation,
        originAddress,
        setOriginAddress,
        targetAddress,
        setTargetAddress,
        distanceFromCenter,
        setDistanceFromCenter,
        openTitle,
        setOpenTitle,
        openMessage,
        setOpenMessage,
        open,
        setOpen,
        docType,
        setDocType,
        files,
        setFiles,
        uploadType,
        setUploadType,
        isUserNotSignatory,
        setIsUserNotSignatory,
        signatories,
        setSignatories,
        transactionId,
        setTransactionId,
        invoiceNo,
        setInvoiceNo,
        fromDocument,
        setFromDocument,
        initialLocation,
        setInitialLocation,
        level1,
        setLevel1,
        level2,
        setLevel2,
        amount,
        setAmount,
        lvl0, setLvl0,
        lvl1, setLvl1,
        lvl2, setLvl2,
        lvl3, setLvl3,
        amt, setAmt,
        docsType, setDocsType,
        setNotaryFee,
        paymentMethodId,
        setPaymentMethodId,
        gCashSource,
        setGCashSource,
        notaryFee, setNotaryFee,
        documentOption, setDocumentOption,
        booking, setBooking,
        gCashResult, setGCashResult,
        lastLocation, setLastLocation,
        lastLocationLng, setLastLocationLng,
        lastLocationLat, setLastLocationLat,
        clickNext, setClickNext,
        openDocument, setOpenDocument,
        openDescribe, setOpenDescribe,
        openOptions, setOpenOptions,
        openOptionsMain, setOpenOptionsMain,
        openOptionsStep2, setOpenOptionsStep2,
        openMapBook, setOpenMapBook,
        refreshMapBook, setRefreshMapBook,
        noInit, setNoInit,
        caller, setCaller,
        resetDescibeItems, setResetDescibeItems,
        rowDataFeesRates, setRowDataFeesRates,
        openFeesRates, setOpenFeesRates,
        openFeesRatesUpdate, setOpenFeesRatesUpdate,
        memberId, setMemberId,
        gridApi, setGridApi,
        feesRates, setFeesRates,
        valueFeesRates, setValueFeesRates,
        membersArray, setMembersArray,
        valueMember, setValueMember,
        userSearchTxId, setUserSearchTxId,
        userSearchTx, setUserSearchTx,
        appointment, setAppointment,
        maxNotaries, setMaxNotaries,
        // openSearchPricing, setOpenSearchPricing,
        openSearchLocation, setOpenSearchLocation,
        openMapBookPricing, setOpenMapBookPricing,
        province, setProvince,
        streetNumber, setStreetNumber,
        barangay, setBarangay,
        cityMunicipality, setCityMunicipality,
        subCityMunicipality, setSubCityMunicipality,
        openSearchLocationPrice, setOpenSearchLocationPrice,
        templateId, setTemplateId,
        displayAmount, setDisplayAmount,
        openPaymentNotice, setOpenPaymentNotice,
        openPaymentOption, setOpenPaymentOption,
        openPaymentUserDetails, setOpenPaymentUserDetails,
        openPaymentRequiredNotification, setOpenPaymentRequiredNotification,
        step, setStep,
        isOpenWaiting, setIsOpenWaiting,
        waitingMessage, setWaitingMessage,
        openExistingPrincipal, setOpenExistingPrincipal,
        openResultsColocationStep2, setOpenResultsColocationStep2,
        openBookNotaryNotice, setOpenBookNotaryNotice,
        openAppointments, setOpenAppointments,
        paymentCheckoutUrl, setPaymentCheckoutUrl,
        openPaymentCheckoutView, setOpenPaymentCheckoutView
      }}
    >
      {children}
    </LocalStateProvider>
  );
}

function useNotaries() {
  const all = useContext(LocalStateContext);
  return all;
}

export { NotariesProvider, useNotaries };
