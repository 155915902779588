import { gql } from '@apollo/client';

export const MEMBER_CHECK = gql`
	query memberCheck($email: String) {
		memberCheck(email: $email) {
			id
			email
		}
	}
`;

export const MEMBER_CHECK_CONTACT = gql`
	query memberCheck($email: String) {
		memberCheck(email: $email) {
			id
			email
			mobileNumber
		}
	}
`;

export const MEMBER_CHECK1 = gql`
	query memberCheck1($email: String) {
		memberCheck1(email: $email) {
			id
			email
		}
	}
`;

export const MEMBER_CHECKV2 = gql`
	query memberCheck($email: String) {
		memberCheck(email: $email) {
			id
			email
			displayName
			hasConfirmedAgreement
			hasRespondedToInvite
			hasInitiallyLoggedIn
			isLoggedIn
			lastName
			firstName
			showName
			showAddress
			showEmail
			showLandline
			showMobileNumber
			showDays
			showHours
			monday
			mondayFrom
			mondayTo
			tuesday
			tuesdayFrom
			tuesdayTo
			wednesday
			wednesdayFrom
			wednesdayTo
			thursday
			thursdayFrom
			thursdayTo
			friday
			fridayFrom
			fridayTo
			saturday
			saturdayFrom
			saturdayTo
			sunday
			sundayFrom
			sundayTo
			mondayApp
			tuesdayApp
			wednesdayApp
			thursdayApp
			fridayApp
			saturdayApp
			sundayApp
			streetNumber
			barangay
			cityMunicipality
			province
			city
			zipCode
			lat
			lng
			serialNumber
			cityProvince
			dateGranted
			expiryDate
			mobileNumber
			landLine
			facebookLink
			twitterLink
			instagramLink
			rollNumber
			subCityMunicipality
			status
			bankName
			bankAccount
		}
	}
`;

export const xxCLIENTS_BY_EMAIL_QUERY = gql`
	query ClientsByEmail($email: String) {
		clientsByEmail(email: $email) {
			member {
				id
			}
			id
			lastName
			firstName
			company
			status
			ord
			parentId
			cases {
				id
				caseCode
				caseTitle
				status
				ord
				parentId
				member {
					id
				}
				activityLog {
					id
					date
					billingType
					hours
					hourlyRate
					activityLogType
					activityLogSubType
					activityLogSubSubType
					amount
					fixedRate
					notes
					startTime
					endTime
					actionDate
					action
					status
					ord
					parentId
				}
				expenseLog {
					id
					date
					billingType
					hours
					hourlyRate
					expenselogType
					amount
					fixedRate
					notes
					startTime
					endTime
					actionDate
					action
					status
					ord
					parentId
				}
			}
		}
	}
`;

export const CLIENTS_BY_EMAIL_QUERY = gql`
	query ClientsByEmailCaseDate(
		$email: String
		$startDate: DateTime
		$endDate: DateTime
	) {
		clientsByEmailCaseDate(
			email: $email
			startDate: $startDate
			endDate: $endDate
		) {
			member {
				id
			}
			id
			lastName
			firstName
			company
			status
			ord
			parentId
			cases {
				id
				caseCode
				caseTitle
				status
				ord
				parentId
				member {
					id
				}
				activityLog {
					id
					date
					billingType
					hours
					hourlyRate
					activityLogType
					activityLogSubType
					activityLogSubSubType
					amount
					fixedRate
					notes
					startTime
					endTime
					actionDate
					action
					status
					ord
					parentId
				}
				expenseLog {
					id
					date
					billingType
					hours
					hourlyRate
					expenselogType
					amount
					fixedRate
					notes
					startTime
					endTime
					actionDate
					action
					status
					ord
					parentId
				}
			}
		}
	}
`;

export const CASES_BY_CLIENT_QUERY = gql`
	query casesByClient($clientId: ID!) {
		casesByClient(clientId: $clientId) {
			id
			caseCode
			caseTitle
			status
			ord
			parentId
			member {
				id
			}
			client {
				id
			}
			activityLog {
				id
				date
				billingType
				hours
				hourlyRate
				activityLogType
				activityLogSubType
				activityLogSubSubType
				amount
				fixedRate
				notes
				startTime
				endTime
				actionDate
				action
				status
				ord
				parentId
			}
			expenseLog {
				id
				date
				billingType
				hours
				hourlyRate
				expenselogType
				amount
				fixedRate
				notes
				startTime
				endTime
				actionDate
				action
				status
				ord
				parentId
			}
		}
	}
`;

export const CASES_BY_CLIENT_NOLOGS_QUERY = gql`
	query casesByClient($clientId: ID!) {
		casesByClient(clientId: $clientId) {
			id
			caseCode
			caseTitle
			status
			ord
		}
	}
`;

export const MEMBER_CHECK_V2 = gql`
	query memberCheck($email: String) {
		memberCheck(email: $email) {
			id
			email
		}
	}
`;

export const CLIENT_FILTER = gql`
	query clientFilter($filter: String, $memberId: ID!) {
		clientFilter(filter: $filter, memberId: $memberId) {
			member {
				id
			}
			id
			lastName
			firstName
			company
			status
			ord
			parentId
			cases {
				id
				caseCode
				caseTitle
				status
				ord
				parentId
				member {
					id
				}
				activityLog {
					id
					date
					billingType
					hours
					hourlyRate
					activityLogType
					activityLogSubType
					activityLogSubSubType
					amount
					fixedRate
					notes
					startTime
					endTime
					actionDate
					action
					status
					ord
					parentId
				}
				expenseLog {
					id
					date
					billingType
					hours
					hourlyRate
					expenselogType
					amount
					fixedRate
					notes
					startTime
					endTime
					actionDate
					action
					status
					ord
					parentId
				}
			}
		}
	}
`;

export const CASE_FILTER = gql`
	query casesFilter($filter: String, $clientId: ID!) {
		casesFilter(filter: $filter, clientId: $clientId) {
			id
			caseCode
			caseTitle
			status
			ord
			parentId
			member {
				id
			}
			client {
				id
			}
			activityLog {
				id
				date
				billingType
				hours
				hourlyRate
				activityLogType
				activityLogSubType
				activityLogSubSubType
				amount
				fixedRate
				notes
				startTime
				endTime
				actionDate
				action
				status
				ord
				parentId
			}
			expenseLog {
				id
				date
				billingType
				hours
				hourlyRate
				expenselogType
				amount
				fixedRate
				notes
				startTime
				endTime
				actionDate
				action
				status
				ord
				parentId
			}
		}
	}
`;

export const CLIENTS_BY_MEMBERNAME_QUERY = gql`
	query ClientsByMemberName(
		$memberId: ID!
		$lastName: String
		$firstName: String
	) {
		clientsByMemberName(
			memberId: $memberId
			lastName: $lastName
			firstName: $firstName
		) {
			id
			lastName
			firstName
			company
			status
		}
	}
`;

export const CLIENTS_BY_MEMBER_QUERY = gql`
	query ClientsByMember($memberId: ID!) {
		clientsByMember(memberId: $memberId) {
			id
			lastName
			firstName
			company
			parentId
			status
			ord
		}
	}
`;

export const IS_BYN_MEMBER_QRY = gql`
	query bynCheck($email: String!) {
		bynCheck(email: $email) {
			email
		}
	}
`;

export const TICKETS_QUERY = gql`
	query {
		tickets {
			id
			ticketNumber
			name
			message
			dateCreation
			dateCompletion
			status
		}
	}
`;

export const NOTARY_QUERY = gql`
	query getNotaries(
		$province: String
		$cityMunicipality: String
		$subCityMunicipality: String
		$barangay: String
	) {
		getNotaries(
			province: $province
			cityMunicipality: $cityMunicipality
			subCityMunicipality: $subCityMunicipality
			barangay: $barangay
		) {
			firstName
			middleName
			lastName
			streetNumber
			barangay
			cityMunicipality
			subCityMunicipality
			province
			city
			zipCode
			lat
			lng
			serialNumber
			mobileNumber
			landLine
			facebookLink
			twitterLink
			instagramLink
			email
			showName
			showAddress
			showEmail
			showLandline
			showMobileNumber
			showDays
			showHours
			# monday
			# mondayFrom
			# mondayTo
			# tuesday
			# tuesdayFrom
			# tuesdayTo
			# wednesday
			# wednesdayFrom
			# wednesdayTo
			# thursday
			# thursdayFrom
			# thursdayTo
			# friday
			# fridayFrom
			# fridayTo
			# saturday
			# saturdayFrom
			# saturdayTo
			# sunday
			# sundayFrom
			# sundayTo
			# mondayApp
			# tuesdayApp
			# wednesdayApp
			# thursdayApp
			# fridayApp
			# saturdayApp
			# sundayApp
			status
		}
	}
`;
export const NOTARY_LATLNG_QUERY = gql`
	query getNotariesLatLng(
		$ref: Float
		$lat1: Float
		$lat2: Float
		$lng1: Float
		$lng2: Float
		$amount: Float
		$documentType: String
		$documentType2: String
		$searchDate: DateTime
		$dtid: String
	) {
		getNotariesLatLng(
			ref: $ref
			lat1: $lat1
			lat2: $lat2
			lng1: $lng1
			lng2: $lng2
			amount: $amount
			documentType: $documentType
			documentType2: $documentType2
			searchDate: $searchDate
			dtid: $dtid
		) {
			ds_ID
			id
			firstName
			middleName
			lastName
			streetNumber
			barangay
			cityMunicipality
			subCityMunicipality
			province
			city
			zipCode
			lat
			lng
			serialNumber
			mobileNumber
			landLine
			facebookLink
			twitterLink
			instagramLink
			email
			showName
			showAddress
			showEmail
			showLandline
			showMobileNumber
			showDays
			showHours
			# monday
			# mondayFrom
			# mondayTo
			# tuesday
			# tuesdayFrom
			# tuesdayTo
			# wednesday
			# wednesdayFrom
			# wednesdayTo
			# thursday
			# thursdayFrom
			# thursdayTo
			# friday
			# fridayFrom
			# fridayTo
			# saturday
			# saturdayFrom
			# saturdayTo
			# sunday
			# sundayFrom
			# sundayTo
			# mondayApp
			# tuesdayApp
			# wednesdayApp
			# thursdayApp
			# fridayApp
			# saturdayApp
			# sundayApp
			status
			price
			description
			availability
			dateGranted
			expiryDate
		}
	}
`;

// export const NOTARY_RATES_QUERY = gql`
// query getNotarialRates($email: String){
//   getNotarialRates(email: $email){
//     id
//     dss_id
//     mm_id
//     email
//     documentType
//     minRate
//     absoluteMinRate
//     rate
//   }
// }
// `

export const SEARCHVIEWS_WEEK_COUNT = gql`
	query searchViewsCountWeek(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		searchViewsCountWeek(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			yyww
			count
		}
	}
`;
export const CLICKS_WEEK_COUNT = gql`
	query clicksCountWeek(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		clicksCountWeek(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			yyww
			count
		}
	}
`;

export const SEARCHVIEWS_MONTH_COUNT = gql`
	query searchViewsCountMonth(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		searchViewsCountMonth(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			yyww
			count
		}
	}
`;

export const CLICKS_MONTH_COUNT = gql`
	query clicksCountMonth(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		clicksCountMonth(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			yyww
			count
		}
	}
`;

export const SEARCHVIEWS_YEAR_COUNT = gql`
	query searchViewsCountYear(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		searchViewsCountYear(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			yyww
			count
		}
	}
`;

export const CLICKS_YEAR_COUNT = gql`
	query clicksCountYear(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		clicksCountYear(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			yyww
			count
		}
	}
`;

export const SEARCHVIEWS_QUERY = gql`
	query searchViews(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		searchViews(emailMobile: $emailMobile, dateFrom: $dateFrom, dateTo: $dateTo) {
			email
			date
		}
	}
`;

export const GETVIEWS_DAYS_COUNT = gql`
	query getViewsDaysCount(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		getViewsDaysCount(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			date
			count
		}
	}
`;

export const GETCLICKS_DAYS_COUNT = gql`
	query getCLicksDaysCount(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		getClicksDaysCount(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			date
			count
		}
	}
`;

export const GETVIEWS_DETAIL = gql`
	query getViewsDetail(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		getViewsDetail(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			province
			city
			count
		}
	}
`;

export const GETCLICKS_DETAIL = gql`
	query getClicksDetail(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		getClicksDetail(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			province
			city
			count
		}
	}
`;

export const GETUSERCLICKS_DETAIL = gql`
	query getUsersClicksDetail(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		getUsersClicksDetail(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			userEmail
			count
		}
	}
`;

export const GETVIEWS_WEEKS_COUNT = gql`
	query getViewsWeeksCount(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		getViewsWeeksCount(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			yyww
			count
		}
	}
`;

export const GETVIEWS_MONTHS_COUNT = gql`
	query getViewsMonthsCount(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		getViewsMonthsCount(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			date
			count
		}
	}
`;

export const GETCLICKS_MONTHS_COUNT = gql`
	query getClicksMonthsCount(
		$emailMobile: String
		$dateFrom: DateTime
		$dateTo: DateTime
	) {
		getClicksMonthsCount(
			emailMobile: $emailMobile
			dateFrom: $dateFrom
			dateTo: $dateTo
		) {
			date
			count
		}
	}
`;

export const GETSERVICEREQUESTS = gql`
	query getServiceRequests($memberId: ID!) {
		getServiceRequests(memberId: $memberId) {
			id
			date
			dateServed
			dateCancelled
			dateDeletion
			requestType
			notes
			status
		}
	}
`;

// DOCUMENTTYPES_QUERY
export const DOCUMENTTYPES_QUERY = gql`
	query getDocumentTypes {
		getDocumentTypes {
			id
			documentType
		}
	}
`;

export const GETINFOFORREVIEW = gql`
	query getUserSearchTxUsingBookingId($bookingId: String!) {
		getUserSearchTxUsingBookingId(bookingId: $bookingId) {
			memberId
			userSearchTextId
			userId
			memberEmail
			userEmail
			memberFirstName
			memberLastName
			userFirstName
			userLastName
			userHasRated
		}
	}
`;

export const GET_BOOKINGS_NOTARY = gql`
	query getBookingsForNotary($memberId: ID!) {
		getBookingsForNotary(memberId: $memberId) {
			id
			date
			province
			city
			subCityMunicipality
			barangay
			streetNumber
			lng
			lat
			amount
			distance
			price
			# appointment {
			# 	id
			# 	status
			# 	dateCancelled
			# 	member {
			# 		id
			# 		email
			# 	}
			# 	user {
			# 		id
			# 		email
			# 		mobileNumber
			# 	}
			# 	booking {
			# 		id
			# 		date
			# 		price
			# 		amount
			# 		status
			# 		reasonForCancel
			# 		docType
			# 		payoutToMember {
			# 			payableToMember
			# 		}
			# 		documentService {
			# 			isPercentage
			# 			documentTemplate {
			# 				displayAmount
			# 				documentTypeLevel2
			# 				documentTopLevel {
			# 					documentTypeLevel1
			# 				}
			# 			}
			# 		}
			# 	}
			# 	title
			# 	start
			# 	end
			# 	# resources
			# }
			# documentService {
			# 	id
			# 	documentTemplate {
			# 		displayAmount
			# 		documentTypeLevel1
			# 		documentTypeLevel2
			# 		documentTopLevel {
			# 			documentTypeLevel1
			# 		}
			# 	}
			# }
			user {
				id
				email
				mobileNumber
			}
			# isUserNotSignatory
			# signatories {
			# 	id
			# 	ord
			# 	fullName
			# }
			transactionId
			invoiceNo
			bookingId
			status
			datePaid
			dateNotarized
			dateCancelled
			reasonForCancel
			docType
			# rating {
			# 	id
			# 	accessible
			# 	fast
			# 	friendly
			# 	review
			# 	user {
			# 		id
			# 		lastName
			# 	}
			# }
			# userHasRated
			payment {
				id
				date
				description
				paymentMethod
				amount
				netAmount
				fee
				foreignFee
				disputed
				status
			}
			documentFile {
				ord
				filePath
				fileName
				contentType
			}
			bookingStatus {
				id
				status
				date
				remarks
			}
			member {
				id
				# firstName
				# middleName
				# lastName
				# streetNumber
				# barangay
				# cityMunicipality
				# cityProvince
				# province
				# city
				# zipCode
			}
		}
	}
`;

export const GET_BOOKINGS_USER = gql`
	query getBookingsForUser($userId: ID!) {
		getBookingsForUser(userId: $userId) {
			id
			date
			province
			city
			subCityMunicipality
			barangay
			streetNumber
			lng
			lat
			amount
			distance
			price
			# appointment {
			# 	id
			# 	status
			# 	dateCancelled
			# 	member {
			# 		id
			# 		email
			# 	}
			# 	user {
			# 		id
			# 		email
			# 		mobileNumber
			# 	}
			# 	booking {
			# 		id
			# 		date
			# 		price
			# 		amount
			# 		status
			# 		reasonForCancel
			# 		docType
			# 		payoutToMember {
			# 			payableToMember
			# 		}
			# 		documentService {
			# 			isPercentage
			# 			documentTemplate {
			# 				displayAmount
			# 				documentTypeLevel2
			# 				documentTopLevel {
			# 					documentTypeLevel1
			# 				}
			# 			}
			# 		}
			# 	}
			# 	title
			# 	start
			# 	end
			# 	# resources
			# }
			# documentService {
			# 	id
			# 	documentTemplate {
			# 		displayAmount
			# 		documentTypeLevel1
			# 		documentTypeLevel2
			# 		documentTopLevel {
			# 			documentTypeLevel1
			# 		}
			# 	}
			# }
			user {
				id
				# firstName
				# middleName
				# lastName
			}
			# isUserNotSignatory
			# signatories {
			# 	id
			# 	ord
			# 	fullName
			# }
			transactionId
			invoiceNo
			bookingId
			status
			datePaid
			dateNotarized
			dateCancelled
			reasonForCancel
			docType
			# rating {
			# 	id
			# 	accessible
			# 	fast
			# 	friendly
			# 	review
			# 	user {
			# 		id
			# 		lastName
			# 	}
			# }
			# userHasRated
			payment {
				id
				date
				description
				paymentMethod
				amount
				netAmount
				fee
				foreignFee
				disputed
				status
			}
			documentFile {
				ord
				filePath
				fileName
				contentType
			}
			bookingStatus {
				id
				status
				date
				remarks
			}
			member {
				id
				firstName
				middleName
				lastName
				streetNumber
				mobileNumber
				# barangay
				# cityMunicipality
				# cityProvince
				# province
				# city
				# zipCode
			}
		}
	}
`;

export const GET_NOTARY_BOOKINGS_COUNT_DAY = gql`
	query getNotaryBookingsCountDay($date: DateTime!) {
		getNotaryBookingsCountDay(date: $date) {
			count
			date
		}
	}
`;

export const GET_DOCUMENT_TEMPLATES_FOR_DESCRIBE_AUTOCOMPLETE = gql`
	query getDocumentTemplatesForDescribeForAutoComplete {
		getDocumentTemplatesForDescribeForAutoComplete {
			dtid
			docType
			goUpload
			priceCalc
			displayAmount
		}
	}
`;

export const GET_DOCUMENT_TEMPLATES_V2 = gql`
	query getDocumentTemplates {
		getDocumentTemplatesV2 {
			id
			ord
			documentTypeLevel1
			documentTypeLevel2
		}
	}
`;

export const GET_LAST_LOCATION = gql`
	query getLastLocation($email: String!) {
		getLastLocation(email: $email) {
			lastLocation
			lastLocationLat
			lastLocationLng
			justRegistered
		}
	}
`;

export const GET_BOOKING_DETAILS = gql`
	query getBookingDetails($userSearchTextId: ID!) {
		getBookingDetails(userSearchTextId: $userSearchTextId) {
			id
			date
			province
			city
			subCityMunicipality
			barangay
			streetNumber
			lng
			lat
			amount
			distance
			price
			member {
				id
				email
			}
			documentTemplate {
				id
				documentTypeLevel1
				documentTypeLevel2
			}
			documentService {
				id
				rate
				fixedFee
				enabled
				member {
					id
					email
				}
				documentTemplate {
					id
					displayAmount
					documentTypeLevel1
					documentTypeLevel2
					documentTopLevel {
						documentTypeLevel1
					}
				}
			}
			user {
				id
				email
			}
			isUserNotSignatory
			signatories {
				id
				fullName
			}
			transactionId
			invoiceNo
			bookingId
			status
			bookingStatus {
				id
				status
				date
			}
			datePaid
			dateNotarized
			dateCancelled
			reasonForCancel
			docType
			rating {
				id
			}
			userHasRated
			payment {
				id
			}
			isReferred
			isLocked
			dateClassified
			dateDenied
			documentFile {
				id
				filePath
				fileName
				contentType
			}
			adminClassified {
				id
			}
			adminReferred {
				id
			}
			adminDenied {
				id
			}
			currentSorter {
				id
			}
		}
	}
`;

export const CREATE_PAYMENT_INTENT = gql`
	query createPaymentIntent($amount: Int!) {
		createPaymentIntent(amount: $amount) {
			paymentIntentId
			clientKey
		}
	}
`;

export const GET_BOOKING_UPDATE_USER = gql`
	query getBookingUpdateUser($userId: ID!) {
		getBookingUpdateUser(userId: $userId) {
			id
			transactionId
			invoiceNo
			status
			bookingStatus {
				id
				status
				date
			}
			docType
			documentService {
				id
				rate
				fixedFee
				isPercentage
				documentTemplate {
					id
					displayAmount
					description
				}
			}
			amount
			date
			dateClassified
			dateDenied
			lat
			lng
			documentTemplate {
				displayAmount
				description
				documentTypeLevel1
				documentTypeLevel2
			}
		}
	}
`;

export const CHECK_BUSINESS_HOURS = gql`
	query checkIfInsideBusinessHours {
		checkIfInsideBusinessHours {
			isInsideBusinessHours
		}
	}
`;

export const GET_PAYMENT = gql`
	query getPayment($userSearchTextId: ID!) {
		getPayment(userSearchTextId: $userSearchTextId) {
			id
			paymentIntentSourceId
			paymentRefId
			bookingId
			status
			payableToMember
			booking {
				id
				date
				transactionId
				invoiceNo
				lng
				lat
				amount
				distance
				price
				docType
				documentService {
					id
					rate
					fixedFee
					isPercentage
					documentTemplate {
						id
						documentTypeLevel1
						documentTypeLevel2
						displayAmount
					}
				}
				user {
					email
					mobileNumber
				}
				member {
					id
					email
					firstName
					lastName
					streetNumber
					barangay
					cityMunicipality
					city
					province
					landLine
					mobileNumber
					monday
					mondayFrom
					mondayTo
					tuesday
					tuesdayFrom
					tuesdayTo
					wednesday
					wednesdayFrom
					wednesdayTo
					thursday
					thursdayFrom
					thursdayTo
					friday
					fridayFrom
					fridayTo
					saturday
					saturdayFrom
					saturdayTo
					sunday
					sundayFrom
					sundayTo
				}
				status
				bookingStatus {
					id
					status
					date
				}
			}
		}
	}
`;

export const GET_REASON_FOR_DENIAL_NOTARY = gql`
	query {
		getReasonForDenialNotary {
			id
			value
			remarks
		}
	}
`;
export const GET_FEES_RATES_QUERY = gql`
	query getFeesRates($memberId: ID!) {
		getFeesRates(memberId: $memberId) {
			enabledForNotary
			ord
			dtl_ID
			documentTypeLevel1
			ord_dt
			documentTypeLevel2
			dt_ID
			enabledAsLevel2
			onlyForSorters
			displayAmount
			ds_ID
			member_ID
			isPercentage
			rate
			fixedFee
			email
			firstName
			middleName
			lastName
			expiryDate
		}
	}
`;

export const GET_STATEMENT_BOOKINGS = gql`
	query getStatementBookings($statementId: ID!) {
		getStatementBookings(statementId: $statementId) {
			transactionId
			invoiceNo
			bookingDate
			datePaid
			statusDate
			status
			documentTypeLevel1
			documentTypeLevel2
			documentAmount
			paymentMethod
			paymentDate
			paymentRefId
			last4
			brand
			paymentAmount
			commission
			netAmount
			fee
			foreignFee
			payableToMember
			receiptNo_PayableToMember
			netIncome
			taxDue
			revenue
			receiptNo_ToPrincipal
			memberId
			notaryEmail
			notaryContactNumber
			notaryFirstName
			notaryMiddleName
			notaryLastName
			n_StreetNumber
			n_Barangay
			n_CityMunicipality
			n_City
			n_Province
			customerEmail
			customerContactNumber
			firstName
			middleName
			lastName
			streetNumber
			barangay
			cityMunicipality
			city
			province
			id
		}
	}
`;

export const GET_STATEMENTS_MEMBERID = gql`
	query getStatementsByMemberId($status: [String!]!, $memberId: ID!) {
		getStatementsByMemberId(memberId: $memberId, status: $status) {
			id
			status
			lastName
			firstName
			email
			streetNumber
			barangay
			cityMunicipality
			province
			city
			zipCode
			bankName
			bankAccount
			statements {
				id
				amount
				status
				dateCreated
				dateApproved
				datePaid
				statementRefNo
				bankAccount
				bankName
				createdBy {
					id
					email
				}
				approvedBy {
					id
					email
				}
				bookings {
					id
					status
					transactionId
					invoiceNo
					payoutToMember {
						payableToMember
					}
					bookingStatus {
						id
						date
						status
						remarks
					}
				}
				period {
					dateFrom
					dateTo
				}
			}
		}
	}
`;

export const GET_APPOINTMENTS_PRINCIPAL = gql`
	query getAppointmentsPrincipal($memberId: ID!) {
		getAppointmentsPrincipal(memberId: $memberId) {
			id
			status
			dateCancelled
			member {
				id
				email
				lastName
				firstName
				streetNumber
				barangay
				cityMunicipality
				subCityMunicipality
				province
				city
				mobileNumber
			}
			user {
				id
				email
				mobileNumber
			}
			booking {
				id
				date
				price
				amount
				status
				reasonForCancel
				docType
				member {
					id
					email
					lastName
					firstName
					streetNumber
					barangay
					cityMunicipality
					subCityMunicipality
					province
					city
					mobileNumber
				}
				payoutToMember {
					payableToMember
				}
				documentService {
					isPercentage
					documentTemplate {
						displayAmount
						documentTypeLevel2
						documentTopLevel {
							documentTypeLevel1
						}
					}
				}
			}
			title
			start
			end
			# resources
		}
	}
`;

export const GET_APPOINTMENTS_FOR_PRINCIPAL = gql`
	query getAppointmentsForPrincipal($memberId: ID!) {
		getAppointmentsForPrincipal(memberId: $memberId) {
			id
			status
			dateCancelled
			member {
				id
				email
				lastName
				firstName
				streetNumber
				barangay
				cityMunicipality
				subCityMunicipality
				province
				city
				mobileNumber
			}
			user {
				id
				email
				mobileNumber
			}
			booking {
				id
				date
				price
				amount
				status
				reasonForCancel
				docType
				member {
					id
					email
					lastName
					firstName
					streetNumber
					barangay
					cityMunicipality
					subCityMunicipality
					province
					city
					mobileNumber
				}
				payoutToMember {
					payableToMember
				}
				documentService {
					isPercentage
					documentTemplate {
						displayAmount
						documentTypeLevel2
						documentTopLevel {
							documentTypeLevel1
						}
					}
				}
			}
			title
			start
			end
			# resources
		}
	}
`;

export const GET_APPOINTMENTS = gql`
	query getAppointments($memberId: ID!) {
		getAppointments(memberId: $memberId) {
			id
			status
			dateCancelled
			member {
				id
				email
			}
			user {
				id
				email
				mobileNumber
			}
			booking {
				id
				date
				price
				amount
				status
				reasonForCancel
				docType
				payoutToMember {
					payableToMember
				}
				documentService {
					isPercentage
					documentTemplate {
						displayAmount
						documentTypeLevel2
						documentTopLevel {
							documentTypeLevel1
						}
					}
				}
			}
			title
			start
			end
			# resources
		}
	}
`;

export const GET_BLOCKEDOFF_TEMPLATES = gql`
	query getBlockedOffTemplates($memberId: ID!) {
		getBlockedOffTemplates(memberId: $memberId) {
			id
			day
			start
			end
		}
	}
`;

export const GET_BLOCKEDOFF = gql`
	query getBlockedOff($memberId: ID!) {
		getBlockedOff(memberId: $memberId) {
			id
			day
			start
			end
		}
	}
`;

export const GET_BLOCKEDOFF_TEMPLATES_BYDAY = gql`
	query getBlockedOffTemplatesByDay($memberId: ID!) {
		getBlockedOffTemplatesByDay(memberId: $memberId) {
			day
			blockedOffTemplate {
				id
				day
				start
				end
			}
		}
	}
`;

export const GET_PRESENT_BLOCKEDOFF = gql`
	query getPresentBlockedOff($memberId: ID!) {
		getPresentBlockedOff(memberId: $memberId) {
			id
			day
			start
			end
		}
	}
`;

export const GET_DOC_SERVICES_MEMBER = gql`
	query getDocumentServicesForMember($memberId: ID!) {
		getDocumentServicesForMember(memberId: $memberId) {
			id
			rate
			fixedFee
			enabled
			documentClass {
				id
				description
				ord
				priceCalc
			}
		}
	}
`;

export const LOG_IN = gql`
	query logIn($email: String!) {
		logIn(email: $email) {
			id
			email
			displayName
			firstName
			lastName
			middleName
			hasConfirmedAgreement
			hasRespondedToInvite
			hasInitiallyLoggedIn
			isLoggedIn
			role
		}
	}
`;

export const CHECK_LOG_IN_USER = gql`
	query checkEmailMobileForLogInUser($emailMobile: String!) {
		checkEmailMobileForLogInUser(emailMobile: $emailMobile) {
			id
			email
			displayName
			firstName
			lastName
			middleName
			hasConfirmedAgreement
			hasRespondedToInvite
			hasInitiallyLoggedIn
			isLoggedIn
			role
			latestToken
		}
	}
`;

export const GET_REFUND_STUB = gql`
  query getRefundStubDetails($refundStubId: ID!){
    getRefundStubDetails(refundStubId: $refundStubId) {
      id
      date
      amount
      payment {
        id
      }
      refundId
      refundType
      refund {
        id
        status
      }
      status
      log
      booking {
        id
      }
      member {
        id
      }
      remarks
    }
  }
`

export const CHECK_MEMBER_EXISTS_NAME = gql`
  query memberExistsByName(
    $firstName: String!
    $lastName: String!
  ) {
    memberExistsByName(
      firstName: $firstName
      lastName: $lastName
    )
  }
`


export const CHECK_MEMBER_EXISTS_BARANGAY = gql`
  query barangayMemberExists(
    $province: String!
    $cityMunicipality: String!
    $subCityMunicipality: String!
    $barangay: String!
  ) {
    barangayMemberExists(
      province: $province
      cityMunicipality: $cityMunicipality
      subCityMunicipality: $subCityMunicipality
      barangay: $barangay
    )
  }
`
export default MEMBER_CHECK;
